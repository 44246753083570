import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {calculateOptions, getNextAvailableLock} from '../../util/PeriodLocks';
import ActionsMenu from '../../../../../../forecast-app/shared/components/action-menu/actions_menu';
import {showFixedPriceLockModal, showFixedPriceOpenLockModal} from '../../util/PeriodLocks';

const CloseMonthButtons = ({isFixedPriceV2, project, lockedAmounts}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {fixedPriceLocks} = project;
	const hasLockedProject = fixedPriceLocks.edges.some(fixedPriceLock => fixedPriceLock.node.locked);

	const nextAvailableLock = getNextAvailableLock(project);
	let disableLockButton = false;
	if (nextAvailableLock) {
		const possibleLocks = calculateOptions(project, true);
		disableLockButton = possibleLocks.length === 0 || project.status === 'DONE';
	}

	return isFixedPriceV2 ? (
		<ActionsMenu
			whiteInner={true}
			options={[
				{
					text: formatMessage({id: 'fixed_price_lock.lock_modal_button'}),
					onClick: () => showFixedPriceLockModal(project, null, lockedAmounts),
					locked: disableLockButton,
					disabledTitle: formatMessage({id: 'fixed_price_lock.tooltip.no_months_to_lock'}),
				},
				{
					text: formatMessage({id: 'fixed_price_lock.unlock_modal_button'}),
					onClick: () => showFixedPriceOpenLockModal(project),
					locked: !hasLockedProject,
					disabledTitle: formatMessage({id: 'fixed_price_lock.tooltip.no_months_to_unlock'}),
				},
			]}
		/>
	) : null;
};

export default createFragmentContainer(CloseMonthButtons, {
	project: graphql`
		fragment CloseMonthButtons_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
				edges {
					node {
						id
						startDate
						endDate
						locked
					}
				}
			}
		}
	`,
});
