import {createFragmentContainer, graphql} from 'react-relay';
import {decompressAggregatedData, decompressData} from '../util/DataDecompressUtil';
import {getCurrency, TABLE_TYPE} from '../util/BudgetUtils';
import {useState} from 'react';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';
import {hasPermission} from '../../../../../forecast-app/shared/util/PermissionsUtil';
import Util from '../../../../../forecast-app/shared/util/util';
import {PERMISSION_TYPE} from '../../../../../Permissions';

function duplicateTotalsRow(rows) {
	const totalRow = rows[rows.length - 1];
	if (totalRow) {
		rows.push(totalRow);
	}
}

const RevenueRecognitionDetailsLoader = ({
	viewer,
	aggregateLevel,
	revenueAggregates,
	manualRecognitionAggregates,
	billableTimeAggregates,
	varianceAndAdjustmentAggregates,
	costAggregates,
	allAggregates,
	tableType,
	children,
	retry,
	timestamp,
}) => {
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();

	const showManualRevenueRecognition =
		hasPermission(PERMISSION_TYPE.MANUAL_REVENUE_RECOGNITION) && tableType === TABLE_TYPE.TIME_REVENUE_RECOGNITION;

	const [financialQueryTimestamp, setFinancialQueryTimestamp] = useState(timestamp);
	if (timestamp !== financialQueryTimestamp) {
		setFinancialQueryTimestamp(timestamp);
		retry();
	}
	const {aggregatedFinancialNumbers, financialNumbers, expenseAggregatedFinancialNumbers} = viewer.project;
	const currency = getCurrency(viewer.company, viewer.project);
	const processedData = decompressData(
		aggregatedFinancialNumbers,
		financialNumbers,
		currency,
		aggregateLevel,
		allAggregates,
		false
	);

	const processedExpenseData = decompressData(
		expenseAggregatedFinancialNumbers,
		[],
		currency,
		aggregateLevel,
		['billableTotalTimeAndExpensesAtCompletion'],
		false
	);

	const revenueData = {
		rows: processedData.rows.filter(row => revenueAggregates.includes(row.aggregate)),
		columns: processedData.columns,
	};
	const manualRecognitionData = showManualRevenueRecognition
		? {
				rows: processedData.rows.filter(row => manualRecognitionAggregates.includes(row.aggregate)),
				columns: processedData.columns,
		  }
		: null;
	const billableTimeData = {
		rows: processedData.rows.filter(row => billableTimeAggregates.includes(row.aggregate)),
		columns: processedData.columns,
	};
	const varianceAndAdjustmentData = {
		rows: processedData.rows.filter(row => varianceAndAdjustmentAggregates.includes(row.aggregate)),
		columns: processedData.columns,
	};
	const costData = {
		rows: processedData.rows.filter(row => costAggregates.includes(row.aggregate)),
		columns: processedData.columns,
	};

	const accumulatedNumbers = decompressAggregatedData(aggregatedFinancialNumbers, allAggregates);

	const recognitionAmountAccumulatedRow = accumulatedNumbers.map(financialNumbers => {
		return {value: financialNumbers.recognitionAmountAccumulated, startDate: financialNumbers.date};
	});
	const recognitionPercentageAccumulatedRow = accumulatedNumbers.map(financialNumbers => {
		return {
			value:
				tableType === TABLE_TYPE.TIME_REVENUE_RECOGNITION
					? financialNumbers.recognitionPercentageAccumulated
					: tableType === TABLE_TYPE.EXPENSE_REVENUE_RECOGNITION
					? financialNumbers.expenseRevenuePercentageAccumulated
					: 0,
			startDate: financialNumbers.date,
		};
	});
	const billableTimeAccumulatedRow = accumulatedNumbers.map(financialNumbers => {
		return {value: financialNumbers.billableTimeAccumulated, startDate: financialNumbers.date};
	});
	duplicateTotalsRow(recognitionAmountAccumulatedRow);
	duplicateTotalsRow(recognitionPercentageAccumulatedRow);
	duplicateTotalsRow(billableTimeAccumulatedRow);

	const estimatedCostAccumulatedRow = accumulatedNumbers.map(financialNumbers => {
		return {value: financialNumbers.estimatedCostAccumulated, startDate: financialNumbers.date};
	});
	const estimatedCostPercentageAccumulatedRow = accumulatedNumbers.map(financialNumbers => {
		return {
			value:
				tableType === TABLE_TYPE.TIME_REVENUE_RECOGNITION
					? financialNumbers.estimatedCostPercentageAccumulated
					: tableType === TABLE_TYPE.EXPENSE_REVENUE_RECOGNITION
					? financialNumbers.expenseCostPercentageAccumulated
					: 0,
			startDate: financialNumbers.date,
		};
	});
	duplicateTotalsRow(estimatedCostAccumulatedRow);
	duplicateTotalsRow(estimatedCostPercentageAccumulatedRow);

	const tableSuffix =
		tableType === TABLE_TYPE.TIME_REVENUE_RECOGNITION
			? '.recognition_time_table'
			: tableType === TABLE_TYPE.EXPENSE_REVENUE_RECOGNITION
			? '.recognition_expense_table'
			: '';

	const revenueRecognitionCummulativeTable = {
		headlineTextId: 'project_budget.recognition_cummulative_revenue_table_headline' + tableSuffix,
		showHeaderRow: false,
		columns: [...revenueData.columns],
		rows: [
			{
				dataArray: recognitionPercentageAccumulatedRow,
				currency,
				aggregate: 'recognitionPercentageAccumulated',
			},
			{
				dataArray: recognitionAmountAccumulatedRow,
				currency,
				aggregate: 'recognitionAmountAccumulated',
			},
		],
	};

	const billableTimeIndex = billableTimeData.rows.findIndex(row => row.aggregate === 'billableTime');
	if (billableTimeIndex !== -1) {
		billableTimeData.rows.splice(billableTimeIndex, 0, {
			dataArray: billableTimeAccumulatedRow,
			currency,
			aggregate: 'billableTimeAccumulated',
		});
	}
	const timeMaterialTimeMarginIndex = revenueData.rows.findIndex(row => row.aggregate === 'totalMarginAtCompletion');
	if (timeMaterialTimeMarginIndex !== -1) {
		revenueData.rows.splice(timeMaterialTimeMarginIndex + 1, 0, {dataArray: []});
	}

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	const revenueRecognitionTable = {
		headlineTextId: 'project_budget.suggested_revenue_table_headline' + tableSuffix,
		showHeaderRow: !showManualRevenueRecognition,
		columns: [...revenueData.columns],
		rows: [...revenueData.rows],
	};

	const billableTimeTable = {
		headlineTextId:
			(hasFinancialCategoriesUpdate
				? 'project_budget.billable_value_of_service_table_headline'
				: 'project_budget.billable_time_table_headline') + tableSuffix,
		showHeaderRow: false,
		columns: [...billableTimeData.columns],
		rows: [...billableTimeData.rows],
	};

	const varianceAndAdjustmentTable = {
		headlineTextId: 'project_budget.variance_and_adjustment_table_headline' + tableSuffix,
		showHeaderRow: false,
		columns: [...varianceAndAdjustmentData.columns],
		rows: [...varianceAndAdjustmentData.rows],
	};

	const costTable = !hasRevenueWithoutCostAccess
		? {
				headlineTextId: 'project_budget.recognition_cost_table_headline' + tableSuffix,
				showHeaderRow: false,
				columns: [...costData.columns],
				rows: [
					{
						dataArray: estimatedCostPercentageAccumulatedRow,
						currency,
						aggregate: 'estimatedCostPercentageAccumulated',
					},
					{
						dataArray: estimatedCostAccumulatedRow,
						currency,
						aggregate: 'estimatedCostAccumulated',
					},
					...costData.rows,
				],
		  }
		: null;

	let manualRevenueRecognitionTable;
	const expenseData = processedExpenseData.rows[0];
	if (showManualRevenueRecognition && manualRecognitionData) {
		manualRevenueRecognitionTable = {
			headlineTextId: 'project_budget.manual_recognition_revenue_table_headline',
			showHeaderRow: true,
			columns: [...costData.columns],
			rows: [
				{
					dataArray: manualRecognitionData.rows[0].dataArray.map(dataValue => {
						return {
							startDate: dataValue.startDate,
						};
					}),
					aggregate: 'manualRecognitionReset',
				},
				...manualRecognitionData.rows.map(row => {
					if (row.aggregate === 'recognitionAmount') {
						row.aggregate = 'manualRecognitionAmount';
					}
					row.expenseAmounts = expenseData.dataArray.map(data => data.value);
					return row;
				}),
			],
		};
	}
	const allTables = [
		manualRevenueRecognitionTable,
		revenueRecognitionTable,
		revenueRecognitionCummulativeTable,
		billableTimeTable,
		varianceAndAdjustmentTable,
		costTable,
	];

	const result = {
		tables: allTables.filter(table => table && table.rows && table.rows.length),
	};
	return children(result);
};

export const revenueRecognitionDetailsLoaderQuery = graphql`
	query RevenueRecognitionDetailsLoader_Query(
		$projectId: ID!
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$aggregateLevel: AggregateLevel!
		$aggregates: [FinancialAggregatedNumber]!
		$searchQuery: TaskSearchQueryType
		$expenseSearchQuery: TaskSearchQueryType
	) {
		viewer {
			actualPersonId
			component(name: "revenue_recognition_details_loader")
			...RevenueRecognitionDetailsLoader_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
					searchQuery: $searchQuery
					expenseSearchQuery: $expenseSearchQuery
				)
		}
	}
`;

export default createFragmentContainer(RevenueRecognitionDetailsLoader, {
	viewer: graphql`
		fragment RevenueRecognitionDetailsLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			aggregateLevel: {type: "AggregateLevel!"}
			aggregates: {type: "[FinancialAggregatedNumber]!"}
			searchQuery: {type: "TaskSearchQueryType"}
			expenseSearchQuery: {type: "TaskSearchQueryType"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				rateCard {
					currency
				}
				aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
					convertToProjectCurrency: true
					addAccumulatedNumbers: true
					searchQuery: $searchQuery
				)
				expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: [billableTotalTimeAndExpensesAtCompletion]
					convertToProjectCurrency: true
					addAccumulatedNumbers: true
					searchQuery: $expenseSearchQuery
				)
				financialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
					searchQuery: $searchQuery
				) {
					recognitionAmount
					recognitionPercentage
					expenseRevenuePercentage
					recognitionProfit
					recognitionProfitPercentage
					billableTime
					surplus
					surplusPercentage
					estimatedCost
					estimatedCostPercentage
					expenseCostPercentage
					suggestedRevenue
					suggestedRevenuePercentage
					suggestedProfit
					suggestedProfitPercentage
				}
			}
		}
	`,
});
